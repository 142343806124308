<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="新增激活"
        :visible.sync="visible"
        width="40%"
        @close="closeDialog"
        @open="openDialog">

        <!-- 弹窗主体 start -->
        <div class="body">
            <div class="title">导入设备唯一标识</div>
            <el-input
                size="small"
                v-model.trim="form.identify"
                placeholder="请输入设备唯一标识">
            </el-input>
            <div class="title">
                上传文件
                <el-link href="https://otafile.lango-tech.cn/otafile/ly/uniqueKey.xls" type="primary"
                         style="font-size: 12px;margin-left: 5px;">模版下载(Excel)
                </el-link>
            </div>
            <div class="upload-body">

                <el-upload
                    class="upload-demo"
                    ref="upload"
                    action="/lgiot/azsgw/authorization/readdata"
                    :headers="{
                            accessToken: $token.getToken().accessToken,
                            refreshToken: $token.getToken().refreshToken
                        }"
                    drag
                    :on-success="onSuccess"
                    :on-error="onError"
                    :before-upload="onbeforeunload"
                    :limit="1"
                    :show-file-list="false"
                    :loading="fileLoading"
                    accept=".xlsx,.xls">
                    <div v-if="!file.name">
                        <i class="el-icon-upload" style="margin-top: 20px;font-size: 40px;"></i>
                        <div class="el-upload__text">点击或将文件拖拽到这里上传</div>
                        <div class="el-upload__subtitle">支持文件格式：.xlsx</div>
                    </div>
                    <div style="margin-top: 50px;font-size: 20px;color: #3296FA;" v-else>
                        <span>{{ file.name }}</span>
                        <el-button type="text" style="padding: 0px;"
                                   icon="el-icon-delete" @click.stop="delBtn()"></el-button>
                    </div>
                </el-upload>
            </div>
        </div>
        <!-- 弹窗主体 end -->

        <!-- 弹窗底部按钮 start -->
        <div class="demo-drawer__footer" style="text-align: right;margin-top: 30px">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确 定</el-button>
        </div>
        <!-- 弹窗底部按钮 end -->

    </el-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import axios from "axios";

export default {

    name: "addActivationDialog",

    computed: {
        ...mapState('empower', ['addActivationDialogVisible'])
    },

    watch: {
        //新增激活弹窗开关
        addActivationDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            },
            deep: true
        }
    },

    data() {

        return {

            //弹窗开关
            visible: false,

            //提交加载按钮加载动画开关
            loading: false,

            //文件上传加载动画开关
            fileLoading: false,

            //文件上传附带参数
            data: {},

            form: {
                //唯一标识
                identify: "",
                uniqueKeys: ""
            },

            //上传附件信息
            file: {},

        }

    },

    methods: {

        ...mapActions('empower', ['closeAddActivationDialog', 'importMemberAuthorization']),

        /**
         * 弹窗关闭回调
         */
        closeDialog() {
            this.form = {
                identify: ""
            }
            this.loading = false;
            this.file = {};
            this.closeAddActivationDialog();
        },

        /**
         * 打开弹窗回调
         */
        openDialog() {

        },

        /**
         * 清空文件按钮
         */
        delBtn() {
            this.file = {}
            this.$refs.upload.clearFiles()
        },

        /**
         * 文件上传前回调
         * @param file
         * @returns {Promise<unknown>}
         */

        onbeforeunload(file) {
            this.fileLoading = true;
        },

        /**
         * 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
         * @param file
         */
        onChange(file) {
            this.file = file;
        },

        /**
         * 文件上传成功回调
         * @param response
         * @param file
         * @param fileList
         */
        onSuccess(response, file, fileList) {
            this.fileLoading = false;

            if (response.success) {
                this.file.name = file.name;
                this.form.uniqueKeys = response.result;
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
            }
        },

        /**
         * 文件上传失败回调
         * @param err
         * @param file
         * @param fileList
         */
        onError(err, file, fileList) {
            this.fileLoading = false;
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        },

        /**
         * 提交新增
         */
        submit() {

            if (this.form.uniqueKeys === "" && this.form.identify === "") {
                this.$dialog.showMessage("请填写唯一标识或者上传excel文件", this.$config.TOAST_WARNING);
                return;
            }

            this.loading = true;

            let uniqueKeys =
                (this.form.uniqueKeys ? this.form.uniqueKeys : "") +
                (this.form.uniqueKeys && this.form.identify ? ',' : '') +
                (this.form.identify ? this.form.identify : "");
            this.importMemberAuthorization({
                authorizationCode: this.$route.query.code,
                uniqueKeys
            }).then(res => {
                this.$dialog.showMessage("新增成功", this.$config.TOAST_SUCCESS);
                this.closeDialog();
                this.$emit('getMsg')
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })

        }

    }

}
</script>

<style scoped>
.body {
    margin-top: 10px;
}

.title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.90);
}

.el-input {
    margin: 10px 0;
}

.upload-body {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    height: 200px;
    border-radius: 4px;
    margin-top: 10px;
}

.upload-demo {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

/deep/ .el-upload-dragger, /deep/ .el-upload {
    width: 100%;
    height: 100%;
}

.el-upload__text {
    margin-top: 10px;
}

.el-upload__subtitle {
    font-size: 12px;
    margin-top: 5px;
}
</style>
